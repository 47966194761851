import React from 'react';

function SocialMediaLink({ name, icon, url }) {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <i className={icon}></i>
    </a>
  );
}

export default SocialMediaLink;
