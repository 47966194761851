import React from 'react'
import Images from '../Images';
import FetchCSVData from './FetchCSVData.js';
import constants from '../constants';

export default function Research_Desktop() {
    // const research = FetchCSVData().Research;
    const research = constants.Research;

    return (
        <>
            {/* <div className="marqueeText pl-0">
                <marquee>
                    Research
                </marquee>
            </div> */}

            <section className="news research py-5 px-4" id="publications">
                <div className="">
                    <h5 className="font-ram py-2 text-uppercase retro" data-aos="fade-up" data-aos-duration="1000">Research</h5>
                </div>
                <div className="row">
                    {research?.map((research, index) => (
                        <React.Fragment key={index}>
                            <span href={research.link} target="_blank" rel="noreferrer" className="cardArticle">
                                <div className="card mx-2 my-2 researchCard" key={index}>
                                    <div className='imgContainer'>
                                        <img src={research.image} className="card-img-top" alt="..." />
                                        {/* <img src={Images[research.image]} className="card-img-top" alt="..." /> */}
                                    </div>
                                    <div className="card-body">
                                        <p className="card-title">{research.title}</p>
                                        <p className="card-text">{research.type}, {research.month}, {research.year}</p>
                                        <p className='authorContainer'>
                                            Authors:
                                            <span className='authors'>
                                                {/* {research.authors.split(' - ').map((author, index) => (
                                                <span className="card-text authorsName" key={index}>{author}</span>
                                            ))} */}
                                                {research.authors.map((author, index) => (
                                                    <span className="card-text authorsName" key={index}>{author} </span>
                                                ))}
                                            </span>
                                        </p>
                                        <a href={research.link} target="_blank" rel="noreferrer" className="readMoreSpan btn btn-primary">{research.hover_text}</a>
                                    </div>
                                </div>
                            </span>
                        </React.Fragment >
                    ))}
                </div>
            </section>
        </>
    )
}
