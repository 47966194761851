import React from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export default function SkillProgress(props) {
    const value = props.value
    const className = props.className
    const keyIndex = props.keyIndex
    return (
        <div className='styleSkill' key={keyIndex + 'skills_style'}>
            <CircularProgressbar
                styles={buildStyles({
                    pathColor: `rgba(126, 125, 125, ${value / 100})`,
                    trailColor: '#d6d6d6',
                })}
                value={value}
                text=""
            />
            <i className={`${className} skill_indi`}></i>
        </div>
    )
}
