import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import AboutMe_Mobile from './AboutMe_Mobile.js';
import Achievements_Desktop from './Achievements_Desktop.js';
import Education_Desktop from './Education_Desktop.js';
import Experience_Desktop from './Experience_Desktop.js';
import Popup from './Popup.js';
import Projects_Desktop from './Projects_Desktop.js';
import Research_Desktop from './Research_Desktop.js';
import Skills_Desktop from './Skills_Desktop.js';
import WriteUps_Desktop from './WriteUps_Desktop.js';
import Resume_PDF from "../assets/Mursal's Resume.pdf";
import Images from '../Images.js';

export default function Mobile_Banner() {
    useEffect(() => {
        async function fetchArticles() {
            const response = await fetch(
                'https://dev.to/api/articles?username=mursalfk'
            );
            const data = await response.json();
            data?.map((article) => {
                const needed_data = {
                    title: article.title,
                    description: article.description,
                    url: article.url,
                    image: article.social_image,
                    date: article.published_at,
                    tags: article.tag_list,
                }
                setWriteUps(prevState => [...prevState, needed_data])
            })
        }
        fetchArticles();
    }, [])

    const [isAboutOpen, setIsAboutOpen] = useState(false);
    const [isSkillsOpen, setIsSkillsOpen] = useState(false);
    const [isExperienceOpen, setIsExperienceOpen] = useState(false);
    const [isEducationOpen, setIsEducationOpen] = useState(false);
    const [isProjectsOpen, setIsProjectsOpen] = useState(false);
    const [isAchievementsOpen, setIsAchievementsOpen] = useState(false);
    const [isWriteUpsOpen, setIsWriteUpsOpen] = useState(false);
    const [isResearchOpen, setIsResearchOpen] = useState(false);
    const [writeUps, setWriteUps] = useState([]);
    const [menuOpen, setMenuOpen] = useState(false)

    const togglePopupAbout = () => {
        setIsAboutOpen(!isAboutOpen);
        setMenuOpen(!menuOpen)
    }

    const togglePopupSkills = () => {
        setIsSkillsOpen(!isSkillsOpen);
        setMenuOpen(!menuOpen)
    }

    const togglePopupExperience = () => {
        setIsExperienceOpen(!isExperienceOpen);
        setMenuOpen(!menuOpen)
    }

    const togglePopupEducation = () => {
        setIsEducationOpen(!isEducationOpen);
        setMenuOpen(!menuOpen)
    }

    const togglePopupProjects = () => {
        setIsProjectsOpen(!isProjectsOpen);
        setMenuOpen(!menuOpen)
    }

    const togglePopupAchievements = () => {
        setIsAchievementsOpen(!isAchievementsOpen);
        setMenuOpen(!menuOpen)
    }

    const togglePopupWriteUps = () => {
        setIsWriteUpsOpen(!isWriteUpsOpen);
        setMenuOpen(!menuOpen)
    }

    const togglePopupResearch = () => {
        setIsResearchOpen(!isResearchOpen);
        setMenuOpen(!menuOpen)
    }

    const toggleMenu = () => {
        setMenuOpen(!menuOpen)
    }

    return (
        <section className="site-banner mobile" id="home">
            <div className="banner-area text-center ">
                <div className="author mobile_author text-center ">
                    <div className="mobile-width author-img  text-center">
                        <div className="title_loader-mobile">
                            <span className='font-size-15'>
                                &lt; Hello World /&gt;
                            </span> <br /> <br />
                            <span className='font-size-15'>
                                I am
                            </span>
                            <br /> <br />
                            <h1>Mursal Furqan <br /> <i>Kumbhar</i></h1>
                        </div>
                    </div>
                    <div className={`${menuOpen ? 'contact_react_mobile_top' : ''} contact_reach_mobile`}>
                        <a href="https://www.linkedin.com/in/mursalfurqan/" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-linkedin fa-2x"></i>
                        </a>
                        <a href="https://www.github.com/mursalfk/" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-github fa-2x"></i>
                        </a>
                        <a href="https://www.instagram.com/mursal.dev/" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-instagram fa-2x"></i>
                        </a>
                        <a href="https://www.facebook.com/mursal.furqan/" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-facebook fa-2x"></i>
                        </a>
                        <a href={"https://twitter.com/mursalfurqan/"} target="_blank" rel="noopener noreferrer">
                            <img src={Images.xLogo} alt="xLogoTwitter" className='xLogoTwitter' />
                        </a>
                    </div>
                </div>
                <div className={`${menuOpen ? '' : ''}`}>
                    <div className={`${menuOpen && 'mobile_menu_open_bottom'} mobile_menu_bottom`}>
                        <div onClick={toggleMenu} className="mobile_menu_bottom_line"></div>
                        <div className="mobile_menu_item">
                            <a onClick={togglePopupAbout}><p><i className="fas fa-user"></i> &nbsp; About</p></a>
                            <a onClick={togglePopupSkills}><p><i className="fas fa-code"></i> &nbsp; Skills</p></a>
                            <a onClick={togglePopupExperience}><p><i className="fas fa-briefcase"></i> &nbsp; Experience</p></a>
                            <a onClick={togglePopupEducation}><p><i className="fas fa-graduation-cap"></i> &nbsp; Education</p></a>
                            <a onClick={togglePopupProjects}><p><i className="fas fa-project-diagram"></i> &nbsp; Personal Projects</p></a>
                            <a onClick={togglePopupAchievements}><p><i className="fas fa-trophy"></i> &nbsp; Achievements</p></a>
                            <a onClick={togglePopupWriteUps}><p><i className="fas fa-newspaper"></i> &nbsp; Articles</p></a>
                            <a onClick={togglePopupResearch}><p><i className="fas fa-book"></i> &nbsp; Research</p></a>
                            <a href={Resume_PDF} download="Mursals_Resume.pdf"><p><i className="fas fa-file"></i> &nbsp; Resume</p></a>
                        </div>
                    </div>
                </div>
                <div className="inMobilePopUp">
                    {isAboutOpen && <Popup
                        show={isAboutOpen}
                        content={<AboutMe_Mobile />}
                        handleClose={togglePopupAbout}
                    />}

                    {isSkillsOpen && <Popup
                        show={isSkillsOpen}
                        content={<Skills_Desktop />}
                        handleClose={togglePopupSkills}
                    />}

                    {isExperienceOpen && <Popup
                        show={isExperienceOpen}
                        content={<Experience_Desktop />}
                        handleClose={togglePopupExperience}
                    />}

                    {isEducationOpen && <Popup
                        show={isEducationOpen}
                        content={<Education_Desktop />}
                        handleClose={togglePopupEducation}
                    />}

                    {isProjectsOpen && <Popup
                        show={isProjectsOpen}
                        content={<Projects_Desktop />}
                        handleClose={togglePopupProjects}
                    />}

                    {isAchievementsOpen && <Popup
                        show={isAchievementsOpen}
                        content={<Achievements_Desktop />}
                        handleClose={togglePopupAchievements}
                    />}

                    {isWriteUpsOpen && <Popup
                        show={isWriteUpsOpen}
                        content={<WriteUps_Desktop writeUps={writeUps.slice(0, 5)} />}
                        handleClose={togglePopupWriteUps}
                    />}

                    {isResearchOpen && <Popup
                        show={isResearchOpen}
                        content={<Research_Desktop />}
                        handleClose={togglePopupResearch}
                    />}

                </div>
            </div>
        </section>
    )
}