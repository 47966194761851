import React from 'react';
import Resume_PDF from "../assets/Mursal's Resume.pdf";

export default function Resume() {
    return (
        <>
            {/* <div className="marqueeText pl-0">
                <marquee>
                    Resume
                </marquee>
            </div> */}

            <div className="pdf-viewer" style={{ width: '100%', height: '74vh', position: 'relative' }}>
                <object data={Resume_PDF} type="application/pdf" width="100%" height="98%"></object>
                <a
                    href={Resume_PDF}
                    download="Mursals_Resume.pdf"
                    className="download-btn-pdf-viewer"
                >
                    <i className="fas fa-download" style={{ fontSize: '16px', color: '#000' }}></i>
                    <span className="hover-text-pdf">Download Resume</span>
                </a>
            </div>
        </>
    );
}
