import Images from './Images.js'

const scrambled_phrases = [
    'I am React Developer',
    'I am a Web Designer',
    'I am an AWS Community Builder',
    'I am a Hack Club Leader',
    'I am an Agora.io Student Ambassador',
    'I am a Technology Evangelist',
    'I am a GitKraken Ambassador',
    'I am a Research Student',
    'I am a Tech. Community Nerd',
    'I am a #MSFT Tech. Associate',
    'I am a #MSFT Certified Trainer',
    'I am a Writer',
    'I am a Illustrator',
    'I am a UI/UX Engg.'
]

const Achievements = [
    {
        title: 'Cybersecurity Essentials',
        image: Images.CyberSecurity,
        link: 'https://www.credly.com/badges/f3d12d62-6b91-43fa-8edd-a5793c9760bf',
        issuer: 'Cisco'
    }, {
        title: 'Microsoft Certified Trainer',
        image: Images.MCT,
        link: 'https://www.credly.com/badges/2d6344e7-6eb2-4bc2-86d8-bdd5c88bf351',
        issuer: 'Microsoft'
    }, {
        title: 'MIE - Master Trainer 2020-2021',
        image: Images.MIEMT,
        link: '',
        issuer: 'Microsoft'
    }, {
        title: 'MIE Expert 2020-2021',
        image: Images.MIEExpert,
        link: 'https://www.credly.com/badges/c99a55b6-f361-4481-a333-bd92c0396cf7',
        issuer: 'Microsoft'
    }, {
        title: 'Beta - Microsoft Learn Student Ambassador',
        image: Images.MLSABeta,
        link: 'https://studentambassadors.microsoft.com/en-US/profile/5118',
        issuer: 'Microsoft'
    }, {
        title: 'Data Scientist - DataCamp',
        image: Images.DSDC,
        link: '',
        issuer: 'DataCamp'
    }, {
        title: 'MTA - Network Fundamentals',
        image: Images.MTANF,
        link: 'https://www.credly.com/badges/9c230183-1e15-46f1-89b8-13e3d2b22d58',
        issuer: 'Microsoft'
    }, {
        title: 'Microsoft Student Partner',
        image: Images.MSP,
        link: '',
        issuer: 'Microsoft'
    }, {
        title: 'IBM - Tools for Data Science',
        image: Images.IBMDS,
        link: 'https://www.credly.com/badges/8a04e31c-6e92-434a-907f-7770b2aa1a7a',
        issuer: 'IBM'
    }, {
        title: 'MTA - Introduction to Python',
        image: Images.MTAPython,
        link: 'https://www.credly.com/badges/f4d06871-e6b2-48d1-a248-86b5dba94c6c',
        issuer: 'Microsoft'
    }, {
        title: 'Microsoft Innovative Educator - Trainer',
        image: Images.MIETrainer,
        link: '',
        issuer: 'Microsoft'
    }, {
        title: 'Microsoft Office Specialist - Word Expert',
        image: Images.MOSWordExpert,
        link: 'https://www.credly.com/badges/0043f3cf-3d05-4594-a89a-935c7e7cb4cb',
        issuer: 'Microsoft'
    }, {
        title: 'Microsoft Office Specialist - Excel',
        image: Images.MOSExcel,
        link: 'https://www.credly.com/badges/9bbd6fae-3ec2-40aa-90d1-3e93fbf1b49e',
        issuer: 'Microsoft'
    }, {
        title: 'Microsoft Office Specialist - PowerPoint',
        image: Images.MOSPPT,
        link: 'https://www.credly.com/badges/c595f46b-fe81-4649-bc91-b6c3bc4ca552',
        issuer: 'Microsoft'
    }, {
        title: 'Microsoft Office Specialist - Word',
        image: Images.MOSWord,
        link: 'https://www.credly.com/badges/4a5aaef0-4cf5-461e-9e37-f34280502e60',
        issuer: 'Microsoft'
    }
]

const Qwiklabs = [
    {
        title: 'No Code November 2022 - Apps Scripts',
        image: 'https://cdn.qwiklabs.com/tbPvWL6YaPdPX7NkGb5sO92ozw9%2FRvYWcW1pn6vtrF8%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/2826934',
        issuer: 'Google Cloud'
    }, {
        title: 'No code november 2022',
        image: 'https://cdn.qwiklabs.com/vHhfOqB3zDB8tinSBdahaeUvg20%2FXexHfQM57GskK4I%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/2811223',
        issuer: 'Google Cloud'
    }, {
        title: 'Diwali Game 4: Firecrackers and Firebase',
        image: 'https://cdn.qwiklabs.com/eQjd9zSmOdpx109ueEs%2F1P8nqCWWY3Wi%2BQ3oMQaUoEQ%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/2780031',
        issuer: 'Google Cloud'
    }, {
        title: 'Diwali Game 3: Flowers and Flutter',
        image: 'https://cdn.qwiklabs.com/e6H6n6hpifjK1dSmCCcjHSQ62oU4vKTotiZSRuw62uE%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/2768080',
        issuer: 'Google Cloud'
    }, {
        title: 'Diwali Game 2: Lanterns and Looker',
        image: 'https://cdn.qwiklabs.com/RncuWbitnza1OE3OnpLLM6p4swD5Rt5%2B4NAsYsz5kXg%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/2760120',
        issuer: 'Google Cloud'
    }, {
        title: 'Learn to Earn Cloud Data Challenge: Data Analyst Skills',
        image: 'https://cdn.qwiklabs.com/C3I%2B0K46CZPQTbt1x9aZtG%2FLku30HwgDeSMFtMiN9FE%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/2375567',
        issuer: 'Google Cloud'
    }, {
        title: 'Ensure Access & Identity in Google Cloud',
        image: 'https://cdn.qwiklabs.com/hGGZH2CSJWZ%2F2cKVbuf6WonL3OQ4gosSzKlfvY4Qt0E%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/2132783',
        issuer: 'Google Cloud'
    }, {
        title: 'Build and Secure Networks in Google Cloud',
        image: 'https://cdn.qwiklabs.com/fUkNC8Ujw5C3fdqhXntXppEGL%2BupKOgvE42GWf4Oo68%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/2132686',
        issuer: 'Google Cloud'
    }, {
        title: 'Implement DevOps in Google Cloud',
        image: 'https://cdn.qwiklabs.com/D2HllTNg2udK%2Bpm5qZllRj1S2iZzUPCN915MyyyA7gY%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/2128007',
        issuer: 'Google Cloud'
    }, {
        title: 'Deploy and Manage Cloud Environments with Google Cloud',
        image: 'https://cdn.qwiklabs.com/w0417DKBS9Exgk6fnieEYIVBEzqfqLWGrpHghkMjHpo%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/2122914',
        issuer: 'Google Cloud'
    }, {
        title: 'Kubernetes in Google Cloud',
        image: 'https://cdn.qwiklabs.com/3cUZzotUhC3sWESWmiP9mofbB%2BcZrrX5NiKJEGZBS%2B4%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/2114267',
        issuer: 'Google Cloud'
    }, {
        title: 'Create ML Models with BigQuery ML',
        image: 'https://cdn.qwiklabs.com/8jACSR%2BqgI3p2yem69CxNAyXukexHsSAI5j2W71N%2Blc%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/2107947',
        issuer: 'Google Cloud'
    }, {
        title: 'Monitor and Log with Google Cloud Operations Suite',
        image: 'https://cdn.qwiklabs.com/ht3dxzalukepnESNH85pX%2F5ORl%2ByOrOBH2DoMJEoA7k%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/2102842',
        issuer: 'Google Cloud'
    }, {
        title: 'Build and Optimize Data Warehouses with BigQuery',
        image: 'https://cdn.qwiklabs.com/pvJAPKZACj9RvUOd1IuetIiUFGEfsuGZZMkQJaI0m20%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/2097760',
        issuer: 'Google Cloud'
    }, {
        title: 'Learn to Earn Cloud Challenge: Security',
        image: 'https://cdn.qwiklabs.com/%2B2BUjyowGxANv2OFT5bwu5ssTffbYl5ADMfiS50WgjY%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/1187834',
        issuer: 'Google Cloud'
    }, {
        title: 'Learn to Earn Cloud Challenge: Architecture',
        image: 'https://cdn.qwiklabs.com/zW5Hlf4bQgKVOMHxbSOUp97XgXw2tfIVw%2BAdyfGb3ec%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/1183412',
        issuer: 'Google Cloud'
    }, {
        title: 'Learn to Earn Cloud Challenge: Data',
        image: 'https://cdn.qwiklabs.com/uwjhFjQIn%2BSF%2BeLeZJWHwi%2F4r6ehh2Gm6MZ5EDNxoWU%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/1182350',
        issuer: 'Google Cloud'
    }, {
        title: 'Learn to Earn Cloud Challenge: Essentials',
        image: 'https://cdn.qwiklabs.com/jIwhCzGDnRJwMylCZ%2BegqgLeUnhR1r3vnSFGhJbUiRs%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/1180330',
        issuer: 'Google Cloud'
    }, {
        title: 'Create and Manage Cloud Resources',
        image: 'https://cdn.qwiklabs.com/qigZkhvg9KqENz%2FVGMdHGLby%2FUQ%2Bhr1id2Cbp0GxSvs%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/423434',
        issuer: 'Google Cloud'
    }, {
        title: 'Data Science on Google Cloud',
        image: 'https://cdn.qwiklabs.com/r%2BjRIU1BGmi%2F57D7Ng%2FNNv8WzwUrHWDWHufSvyIKIy4%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/391764',
        issuer: 'Google Cloud'
    }, {
        title: 'Automate Interactions with Contact Center AI',
        image: 'https://cdn.qwiklabs.com/nuoj9TP3JFZ8wrPlAZRhYr%2BXKw5lTIFSJ2TY7alYcxo%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/390484',
        issuer: 'Google Cloud'
    }, {
        title: 'Perform Foundational Data, ML, and AI Tasks in Google Cloud',
        image: 'https://cdn.qwiklabs.com/%2BfUNwBxkIaRRdkzbDGS6GW%2BBbMbyXO6F%2BJg%2B3QrSXeA%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/389689',
        issuer: 'Google Cloud'
    }, {
        title: 'Baseline: Data, ML, AI',
        image: 'https://cdn.qwiklabs.com/h%2FIVtx06Cx%2FlEFFsEhXYDX4yQom0wFGFsAzES6iN%2Fg8%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/389576',
        issuer: 'Google Cloud'
    }, {
        title: 'BigQuery Basics for Data Analysts',
        image: 'https://cdn.qwiklabs.com/bWAeRBJNl9ENT%2B%2F%2FYwynddPswnVP9o2EtsDpMw4rVHc%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/388629',
        issuer: 'Google Cloud'
    }, {
        title: 'Data Science on Google Cloud: Machine Learning',
        image: 'https://cdn.qwiklabs.com/HQ8jNKh%2B3GgyXKK%2FO4rHU9mjdiG686k2KLz8odm8cus%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/388460',
        issuer: 'Google Cloud'
    }, {
        title: 'Advanced ML: ML Infrastructure',
        image: 'https://cdn.qwiklabs.com/CoBcp79oFvpU9thhI6XWCNNFySpjThhMh8NDJjnTMi0%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/387635',
        issuer: 'Google Cloud'
    }, {
        title: 'NCAA® March Madness®: Bracketology with Google Cloud',
        image: 'https://cdn.qwiklabs.com/%2FeGmk%2Bn644%2FOaI%2B%2FG%2BxH2DgVBGJ7W4J63youTvLUMDI%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/387243',
        issuer: 'Google Cloud'
    }, {
        title: 'Explore Machine Learning Models with Explainable AI',
        image: 'https://cdn.qwiklabs.com/0bSya3%2F5VLUuF1UhSN5g8ARZbhzm24GCBuxZ%2FNeuSzw%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/387102',
        issuer: 'Google Cloud'
    }, {
        title: 'Intro to ML: Language Processing',
        image: 'https://cdn.qwiklabs.com/pP4Mzjwe7NsGoAvHEldwoJVwPCm0%2FOQ2EF%2F3ZrOi4f0%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/386868',
        issuer: 'Google Cloud'
    }, {
        title: 'Intro to ML: Image Processing',
        image: 'https://cdn.qwiklabs.com/K3pSZUS8Un4i2txMb5adsx2ZoeLcdNQypn85AuJtdVY%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/386238',
        issuer: 'Google Cloud'
    }, {
        title: 'Integrate with Machine Learning APIs',
        image: 'https://cdn.qwiklabs.com/h%2FCMZVTG3%2Fg7X2zuSD5nF2%2FGTVTrwrgQ6cWd6G6THFg%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/386021',
        issuer: 'Google Cloud'
    }, {
        title: 'Intermediate ML: TensorFlow on Google Cloud',
        image: 'https://cdn.qwiklabs.com/bn3X3Y3rVZT6kQP41qoGm%2FoHxKWxezmhyXowIZ1ColQ%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/385706',
        issuer: 'Google Cloud'
    }, {
        title: 'Networking Fundamentals in Google Cloud',
        image: 'https://cdn.qwiklabs.com/lJEDGt10p12jDIvwllnvgFDn1NLrOZw2ybjjX2k%2FROc%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/283786',
        issuer: 'Google Cloud'
    }, {
        title: 'Cloud Logging',
        image: 'https://cdn.qwiklabs.com/zQIYZE1AtBdUDCYuaxcNVTUfQbuVKwfrZbn36G3XRwo%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/283315',
        issuer: 'Google Cloud'
    }, {
        title: 'Application Development - Python',
        image: 'https://cdn.qwiklabs.com/06Z5ayrixjfvzLTW1skfXk7pJEEe7j0yXuafGIt76rU%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/277468',
        issuer: 'Google Cloud'
    }, {
        title: 'Cloud SQL',
        image: 'https://cdn.qwiklabs.com/LVLn2HMOHOqwpHFE%2BEQFON5KBbhpDJ%2Bs%2FH3I%2B26GN6c%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/277371',
        issuer: 'Google Cloud'
    }, {
        title: 'Understanding Your Google Cloud Costs',
        image: 'https://cdn.qwiklabs.com/2GxKzUeZdj8jB1tlIej4wSVJ2V2INW5ea39KqWuRZzE%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/276347',
        issuer: 'Google Cloud'
    }, {
        title: 'Security & Identity Fundamentals',
        image: 'https://cdn.qwiklabs.com/JR0wU2VmW0y1Wklbq%2FdUnULlIz%2FEI2CQTfw7SlgB5Cc%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/276062',
        issuer: 'Google Cloud'
    }, {
        title: 'Baseline: Infrastructure',
        image: 'https://cdn.qwiklabs.com/Eg%2FSbOy66StYJSJgDWYLrHAMVb%2F28ZfyndLzHYtQPeA%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/275446',
        issuer: 'Google Cloud'
    }, {
        title: 'Google Cloud Essentials',
        image: 'https://cdn.qwiklabs.com/xRejIPM4k6VgI8%2B%2B2Nz5bFHFx8PwK0nn9oQofkJOsS4%3D',
        link: 'https://www.cloudskillsboost.google/public_profiles/e7c9d521-8506-4a4a-bc2e-c718fc91744e/badges/275367',
        issuer: 'Google Cloud'
    }
]

const Research = [
    {
        title: 'Image Denoising Using Autoencoders',
        image: Images.imageDenoiser,
        link: 'https://www.taylorfrancis.com/chapters/edit/10.1201/9781003328414-13/image-denoising-using-autoencoders-mursal-furqan-kumbhar',
        type: 'Research Chapter',
        year: '2023',
        month: 'September',
        authors: [
            'Mursal Furqan Kumbhar',
        ],
        hover_text: 'Full-Text Available'
    }, {
        title: 'An Efficient Approach for User Profiling Through Social Media Analytics',
        image: Images.userProfiling,
        link: 'https://www.researchgate.net/publication/355167541_An_Efficient_Approach_for_User_Profiling_Through_Social_Media_Analytics?_sg%5B0%5D=cGFod9brSJ4rquFlyqFJYr3Ylz3D-yUo8EAS64O9qTp5quEeWR9-Wvmc-zekaKoIOF00ItUG5qxEVBRpwMOsS-kZOjjSKUPXUBNYzwMh.OFwx2dJybca774yDO5Cn2OjHAyHWf9oJAPhZCcRC4epVd7e-0M1HaNn_tg9n3RXK_ws9RdSH8VhvyOTqeRTadQ',
        type: 'Thesis',
        year: '2021',
        month: 'October',
        authors: [
            'Mursal Furqan Kumbhar',
            'Hiba Rajput'
        ],
        hover_text: 'Full-Text Available'
    }, {
        title: 'PIA Accidents Analysis Using Naïve Bayes Classifier',
        image: Images.piaAnalysis,
        link: 'https://www.researchgate.net/publication/348423887_PIA_Accidents_Analysis_Using_Naive_Bayes_Classifier',
        type: 'Conference Paper',
        year: '2020',
        month: 'December',
        authors: [
            'Jamsher Bhanbhro',
            'Faiez Yousuf',
            'Sanam Narejo',
            'Mursal Furqan Kumbhar',
        ],
        hover_text: 'Full-Text Available'
    }, {
        title: 'Heart Disease Prediction using Machine Learning Algorithms',
        image: Images.heartDisease,
        link: 'https://www.researchgate.net/publication/348408218_Heart_Disease_Prediction_using_Machine_Learning_Algorithms',
        type: 'Conference Paper',
        year: '2020',
        month: 'December',
        authors: [
            'Mursal Furqan Kumbhar',
            'Hiba Rajput',
            'Sanam Narejo',
            'Adnan Ashraf',
            'Kanwal Awan'
        ],
        hover_text: 'Full-Text Available'
    }, {
        title: 'Poster - AnxoRelief: Detecting and Relieving Anxiety On-The-Go Using Machine Learning Techniques',
        image: Images.anxo_relief_poster,
        link: 'https://www.researchgate.net/publication/364334566_Detecting_and_Relieving_Anxiety_On-The-Go_Using_Machine_Learning_Techniques',
        type: 'Poster',
        year: '2020',
        month: 'October',
        authors: [
            'Mursal Furqan Kumbhar',
            'Zonera Aleem',
            'Aisha Rajput',
        ],
        hover_text: 'File Available'
    }, {
        title: 'Detecting and Relieving anxiety on-the-go using Machine Learning Techniques',
        image: Images.anxietyDetection,
        link: 'https://www.researchgate.net/publication/362231028_Detecting_and_Relieving_anxiety_on-the-go_using_Machine_Learning_Techniques',
        type: 'Thesis',
        year: '2020',
        month: 'November',
        authors: [
            'Mursal Furqan Kumbhar',
            'Zonera Aleem',
            'Aisha Rajput',
            'Sanam Narejo',
            'Mahaveer Rathi'
        ],
        hover_text: 'Full-Text Available'
    }, {
        title: 'Python libraries for Data Science',
        image: Images.pythonLibrary,
        link: 'https://www.researchgate.net/publication/343820740_Python_libraries_for_Data_Science',
        type: 'Presentation',
        year: '2020',
        month: 'July',
        authors: [
            'Mursal Furqan Kumbhar',
            'Hiba Rajput',
            'Sanam Narejo',
        ],
        hover_text: 'File Available'
    }, {
        title: 'Industrial Robotic Claw for Cottage Industries',
        image: Images.roboticClaw,
        link: 'https://www.researchgate.net/publication/331110443_Industrial_Robotic_Claw_for_Cottage_Industries',
        type: 'Conference Paper',
        year: '2019',
        month: 'January',
        authors: [
            'Mursal Furqan Kumbhar',
            'Mahaveer Rathi',
        ],
        hover_text: 'Full-Text Available'
    }
]

const SkillSetValue = [
    {
        className: "fab fa-html5 fa-4x sk",
        value: 95,
    }, {
        className: "fab fa-css3-alt fa-4x sk",
        value: 90,
    }, {
        className: "fab fa-js-square fa-4x sk",
        value: 75,
    }, {
        className: "fab fa-react fa-4x sk",
        value: 70,
    }, {
        className: "fab fa-node-js fa-4x sk",
        value: 50,
    }, {
        className: "fab fa-python fa-4x sk",
        value: 90,
    }, {
        className: "fab fa-git-alt fa-4x sk",
        value: 80,
    }, {
        className: "fab fa-github fa-4x sk",
        value: 95,
    }, {
        className: "fab fa-aws fa-4x sk",
        value: 50,
    }, {
        className: "fab fa-ubuntu fa-4x sk",
        value: 65,
    }, {
        className: "fab fa-linux fa-4x sk",
        value: 70,
    }, {
        className: "fab fa-figma fa-4x sk",
        value: 95,
    }, {
        className: "fab fa-less fa-4x sk",
        value: 65,
    }, {
        className: "fab fa-sass fa-4x sk",
        value: 90,
    }, {
        className: "fab fa-bootstrap fa-4x sk",
        value: 80,
    }, {
        className: "fab fa-vuejs fa-4x sk",
        value: 45,
    }, {
        className: "fab fa-wordpress fa-4x sk",
        value: 75
    }, {
        className: "fab fa-npm fa-4x sk",
        value: 95
    }, {
        className: "fab fa-yarn fa-4x sk",
        value: 65
    }
]

const education = [
    {
        className: 'fas fa-university fa-3x sk',
        title: 'Masters of Science in Computer Science',
        institute: 'Sapienza University of Rome, Rome, Italy',
        year: 'Batch of Spring - 2022'
    }, {
        className: 'fas fa-brain fa-3x sk',
        title: 'Artificial Intelligence',
        institute: 'Presidential Initiative for Artificial Intelligence & Computing',
        year: 'Batch - 1'
    }, {
        className: 'fas fa-university fa-3x sk',
        title: 'Bachelors of Engineering in Computer Systems Engineering',
        institute: 'Mehran University of Engineering and Technology, Jamshoro',
        year: 'Batch of Fall - 2016'
    }, {
        className: 'far fa-lightbulb fa-3x sk',
        title: 'Intermediate in Pre-Engineering',
        institute: 'Cadet College Petaro, Jamshoro',
        year: 'Batch of 2011'
    }, {
        className: 'fas fa-school fa-3x sk',
        title: 'Secondary School Certificate',
        institute: 'Cadet College Petaro, Jamshoro',
        year: 'Batch of 2011'
    }, {
        className: 'fa fa-graduation-cap fa-4x',
        title: 'Primary School Certificate',
        institute: "St. Bonaventure's High School and College, Qasimabad, Hyderabad",
        year: '2008 - 2011'
    }
]

const experience = [
    {
        className: 'fas fa-cogs fa-3x sk',
        title: 'Research Fellow',
        location: 'Mathlab, SISSA, Trieste, Italy',
        url: 'https://mathlab.sissa.it/',
        details: 'Working on the Computational Web Server Development. Till now I have worked on <a href="https://argos.sissa.it">ARGOS</a>, <a href="https://atlas.sissa.it" target="_blank" rel="noopener noreferrer">ATLAS</a>, and <a href="https://odyssea.sissa.it" target="_blank" rel="noopener noreferrer">Odyssea</a>.',
        duration: 'Feb 2023 - Nov 2023'
    },{
        className: 'fab fa-react fa-3x sk',
        title: 'Software Engineer',
        location: 'E4Solutions, Sydney (Remote)',
        url: 'https://www.e4solutions.io/',
        details: 'Worked with ReactJS on outAbox Solutions.',
        duration: 'Aug 2022 - Nov 2022'
    }, {
        className: 'fab fa-react fa-3x sk',
        title: 'Associate Software Engineer',
        location: 'Diya Interactive, Karachi',
        details: 'Worked with ReactJS & Django to develop web applications, such as QriosityNet & Others.',
        duration: 'Feb 2021 - Feb 2022'
    }, {
        className: 'fab fa-python fa-3x sk',
        title: 'Backend Python Intern',
        location: 'Diya Interactive, Karachi',
        details: 'Worked with Django to develop web applications pilot projects.',
        duration: 'Dec 2020 - Feb 2021'
    }, {
        className: 'fas fa-cogs fa-3x sk',
        title: 'CIT Instructor',
        location: 'Kamyab Jawan Programme, ORIC, MUET',
        details: 'Main reponsibilities include training the youth of Pakistan to empower the future.',
        duration: 'Nov 2020 - Dec 2020'
    }, {
        className: 'fas fa-cog fa-3x sk',
        title: 'STEAM Trainer for 4IR',
        location: "Int'l Islamic Univ. Islamabad Schools, Latifabad, Hyderabad",
        details: 'Worked with IIUISL team to teach and instruct young students about STEAM w.r.t. 4th Industrial Revolution and its integration in their syllabus.',
        duration: 'Sept 2019 - Nov 2019'
    }, {
        className: 'fas fa-network-wired fa-3x sk',
        title: 'Network Analyst (Intern)',
        location: 'Pakistan Telecommunication Limited, Hyderabad<',
        details: 'Worked with PTCL’s customer relation and network analyst team to detect and solve customer complaints. Also worked on Nokia Motive to document faulty lines.',
        duration: 'June 2019 - July 2019'
    }, {
        className: 'fab fa-react fa-3x sk',
        title: 'React Native Developer (Intern)',
        location: 'Codex Innovation Club (CIC) Hub, Hyderabad',
        details: 'Have an experience of 14 Weeks being an React Native Internee. Helped in different projects including: Smart Attendance System (With QR Code) etc.',
        duration: 'June 2018 - July 2018'
    }
]

const projects = [
    {
        title: "Image Difference Slider",
        description: "Image Difference Slider using HTML, CSS and JS, only. It also supports GIFs.",
        link: 'https://github.com/mursalfk/Comparison-Slider',
        demo: 'https://mursalfk.github.io/Comparison-Slider/',
        image: Images.imgSlider,
        tech: ['fab fa-html5 fa-4x sk', 'fab fa-sass fa-4x sk', 'fab fa-css3-alt fa-4x sk', 'fab fa-js-square fa-4x sk']
    }, {
        title: "Development of E-commerce Web Application",
        description: "Development of E-commerce Web Application in Cloud Computing",
        link: 'https://github.com/mursalfk/e-commerce-store',
        demo: 'https://mursalfk.github.io/e-commerce-store/',
        image: Images.eCommerce,
        tech: ['fab fa-react fa-3x sk', 'fab fa-html5 fa-4x sk', 'fab fa-sass fa-4x sk', 'fab fa-css3-alt fa-4x sk', 'fab fa-aws fa-4x sk']
    }, {
        title: 'Smart Yoga Gym',
        description: "Project: Smart Yoga Gym for Human Computer Interaction on Web at Sapienza University of Rome by Mursal Furqan Kumbhar & Srinjan Ghosh",
        link: 'https://github.com/mursalfk/SYGym',
        demo: 'https://mursalfk.github.io/SYGym/',
        image: Images.SmartYogaGym,
        tech: ['fab fa-html5 fa-4x sk', 'fab fa-css3-alt fa-4x sk', 'fab fa-js-square fa-4x sk']
    }, {
        title: 'Postfolio on ReactJS',
        description: "My portfolio created entirely using ReactJS. It's a single page application with a responsive design. It's hosted on Github Pages.",
        link: 'https://github.com/mursalfk/mursalfk.github.io/',
        demo: 'https://mursalfk.com',
        image: Images.personal_Portfolio,
        tech: ['fab fa-react fa-3x sk', 'fab fa-html5 fa-4x sk', 'fab fa-sass fa-4x sk', 'fab fa-css3-alt fa-4x sk']
    }, {
        title: 'Random Gradient Generator',
        description: "I know it's a pretty dumb application as it only gives you a random gradient as output, along with the ability to copy the gradient code to your clipboard if needed, and also tells you the specs of that particular gradient on your screen. I have also integrated React-Toasts to notify the user about the status of your copy-to-clipboard request.",
        link: 'https://github.com/mursalfk/Random-Gradients-for-HTML',
        demo: 'https://random-hex-gradient.netlify.app',
        image: Images.RandomGradientGenerator,
        tech: ['fab fa-react fa-3x sk', 'fab fa-html5 fa-4x sk', 'fab fa-sass fa-4x sk', 'fab fa-css3-alt fa-4x sk']
    }, {
        title: 'Github API Integration',
        description: "This Project is a simple ReactJS application that uses Github API to fetch the user's profile and repositories. It also has a search functionality to search for any user's profile and repositories.",
        link: 'https://github.com/mursalfk/projects.api',
        demo: 'https://projects-api.netlify.app/',
        image: Images.GithubProjectsAPI,
        tech: ['fab fa-html5 fa-4x sk', 'fab fa-css3-alt fa-4x sk']
    }
]

const social_media = [
    { name: 'LinkedIn', icon: 'fab fa-linkedin fa-2x', url: 'https://www.linkedin.com/in/mursalfurqan/' },
    { name: 'GitHub', icon: 'fab fa-github fa-2x', url: 'https://www.github.com/mursalfk/' },
    { name: 'Instagram', icon: 'fab fa-instagram fa-2x', url: 'https://www.instagram.com/mursal.dev/' },
    { name: 'Facebook', icon: 'fab fa-facebook fa-2x', url: 'https://www.facebook.com/mursal.furqan/' }
  ];

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const SlideShow = [
    { title: 'Title 1', image: Images.slideshowimage0, },
    { title: 'Title 2', image: Images.slideshowimage1, },
    { title: 'Title 3', image: Images.slideshowimage2, },
    { title: 'Title 4', image: Images.slideshowimage3, },
    { title: 'Title 5', image: Images.slideshowimage4, },
    { title: 'Title 6', image: Images.slideshowimage5, },
    { title: 'Title 7', image: Images.slideshowimage6, },
    { title: 'Title 8', image: Images.slideshowimage7, },
    { title: 'Title 9', image: Images.slideshowimage8, },
    { title: 'Title 10', image: Images.slideshowimage9, },
    { title: 'Title 11', image: Images.slideshowimage10, },
    { title: 'Title 12', image: Images.slideshowimage11, },
    { title: 'Title 13', image: Images.slideshowimage12, },
    { title: 'Title 14', image: Images.slideshowimage13, },
    { title: 'Title 15', image: Images.slideshowimage14, },
    { title: 'Title 16', image: Images.slideshowimage15, },
    { title: 'Title 17', image: Images.slideshowimage16, },
    { title: 'Title 18', image: Images.slideshowimage17, },
    { title: 'Title 19', image: Images.slideshowimage18, },
    { title: 'Title 20', image: Images.slideshowimage19, },
    { title: 'Title 21', image: Images.slideshowimage20, },
    { title: 'Title 22', image: Images.slideshowimage21, },
    { title: 'Title 23', image: Images.slideshowimage22, },
    { title: 'Title 24', image: Images.slideshowimage23, },
    { title: 'Title 25', image: Images.slideshowimage24, },
    { title: 'Title 26', image: Images.slideshowimage25, },
    { title: 'Title 27', image: Images.slideshowimage26, },
    { title: 'Title 28', image: Images.slideshowimage27, },
    { title: 'Title 29', image: Images.slideshowimage28, },
    { title: 'Title 30', image: Images.slideshowimage29, },
    { title: 'Title 31', image: Images.slideshowimage30, },
    { title: 'Title 32', image: Images.slideshowimage31, },
    { title: 'Title 33', image: Images.slideshowimage32, },
    { title: 'Title 34', image: Images.slideshowimage33, },
]

const constants = {
    Achievements,
    scrambled_phrases: scrambled_phrases,
    SkillSetValue,
    education,
    experience,
    projects,
    responsive,
    Research,
    Qwiklabs,
    SlideShow,
    social_media
}

export default constants