import React from 'react'
import 'react-multi-carousel/lib/styles.css';
import constants from '../constants';

export default function Education_Desktop() {
    return (
        <>
            {/* <div className="marqueeText pl-0">
                <marquee>
                    Education
                </marquee>
            </div> */}

            <section className="reference px-4" id="experience">
                <div className="refer">
                    <h5 className="font-ram text-uppercase retro" data-aos="fade-up" data-aos-duration="1000">Education</h5>
                </div>
                <div className="row mb-5">
                    {constants.education.map((education, index) => (
                        <div className="col-md-4 col-sm-6 mb-4 text-center experience_box" key={index}>
                            <div className="panel-body">
                                <span className='indexNumber'>
                                    {constants.education.length - index}
                                </span>
                                <span className="icon text-secondary iconExperience">
                                    <i className={education.className}>
                                    </i>
                                </span>
                                <h3 className="font-ram">{education.title}</h3>
                                <i>
                                    {education.year}
                                </i>
                                <p className="font-ram font-size-16">
                                    {education.institute}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </section >
        </>
    )
}
