import React, { useEffect, useState } from 'react'
import AboutMe_Desktop from './AboutMe_Desktop.js';
import Achievements_Desktop from './Achievements_Desktop.js';
import Education_Desktop from './Education_Desktop.js';
import Experience_Desktop from './Experience_Desktop.js';
import Misc_Desktop from './Misc.js';
import Popup from './Popup.js';
import Projects_Desktop from './Projects_Desktop.js';
import Research_Desktop from './Research_Desktop.js';
import Skills_Desktop from './Skills_Desktop.js';
import WriteUps_Desktop from './WriteUps_Desktop.js';
import Resume from './Resume.js';
import constants from '../constants';
import SocialMediaLink from './SocialMediaLink.js';
import ModalButtons from './ModalButtons.js';
import FetchCSVData from './FetchCSVData.js';
import Images from '../Images.js';

export default function Desktop_Banner(props) {
    const csvData = FetchCSVData();
    const body = document.querySelector("body");
    const [isAboutOpen, setIsAboutOpen] = useState(false);
    const [isSkillsOpen, setIsSkillsOpen] = useState(false);
    const [isExperienceOpen, setIsExperienceOpen] = useState(false);
    const [isEducationOpen, setIsEducationOpen] = useState(false);
    const [isProjectsOpen, setIsProjectsOpen] = useState(false);
    const [isAchievementsOpen, setIsAchievementsOpen] = useState(false);
    const [isWriteUpsOpen, setIsWriteUpsOpen] = useState(false);
    const [writeUps, setWriteUps] = useState([]);
    const [isResearchOpen, setIsResearchOpen] = useState(false);
    const [isMiscOpen, setIsMiscOpen] = useState(false);
    const [isResumeOpen, setIsResumeOpen] = useState(false);

    const closeAllPopups = () => {
        setIsAboutOpen(false);
        setIsSkillsOpen(false);
        setIsExperienceOpen(false);
        setIsEducationOpen(false);
        setIsProjectsOpen(false);
        setIsAchievementsOpen(false);
        setIsWriteUpsOpen(false);
        setIsResearchOpen(false);
        setIsMiscOpen(false);
        setIsResumeOpen(false);
        body.style.overflow = "auto";
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Escape') {
            closeAllPopups();
        }
    }

    useEffect(() => {
        body.addEventListener('keydown', handleKeyDown);
        return () => {
            body.removeEventListener('keydown', handleKeyDown);
        }
    }, [body, handleKeyDown]);

    useEffect(() => {
        async function fetchArticles() {
            // Fetch data about a user from dev.to
            const response = await fetch(
                'https://dev.to/api/articles?username=mursalfk'
            );
            const data = await response.json();
            data?.map((article) => {
                const needed_data = {
                    title: article.title,
                    description: article.description,
                    url: article.url,
                    image: article.social_image,
                    date: article.published_at,
                    tags: article.tag_list,
                }
                setWriteUps(prevState => [...prevState, needed_data])
            })
        }
        fetchArticles();
    }, [])


    const togglePopup = (setter) => () => {
        setter(prevState => !prevState);
        toggleBodyScroll();
    }

    const toggleCreditsVisibility = (visibility) => {
        const credits = document.querySelector('.loveInBox');
        credits.style.visibility = visibility;
    }

    const toggleBodyScroll = () => {
        body.style.overflow = (body.style.overflow !== "hidden") ? "hidden" : "auto";
    }

    const popupConfig = [
        { show: isAboutOpen, content: <AboutMe_Desktop />, setter: setIsAboutOpen },
        { show: isSkillsOpen, content: <Skills_Desktop />, setter: setIsSkillsOpen },
        { show: isExperienceOpen, content: <Experience_Desktop />, setter: setIsExperienceOpen },
        { show: isEducationOpen, content: <Education_Desktop />, setter: setIsEducationOpen },
        { show: isProjectsOpen, content: <Projects_Desktop />, setter: setIsProjectsOpen },
        { show: isAchievementsOpen, content: <Achievements_Desktop />, setter: setIsAchievementsOpen },
        { show: isWriteUpsOpen, content: <WriteUps_Desktop writeUps={writeUps} />, setter: setIsWriteUpsOpen },
        { show: isResearchOpen, content: <Research_Desktop />, setter: setIsResearchOpen },
        { show: isResumeOpen, content: <Resume />, setter: setIsResumeOpen },
        { show: isMiscOpen, content: <Misc_Desktop />, setter: setIsMiscOpen }
    ];

    return (
        <section className="site-banner desktop" id="home">
            <div className="banner-area text-center ">
                <div className="author text-center ">
                    <div className='nomobile-text' data-aos="fade-up" data-aos-duration="400">
                        <p className='hello'>
                            &lt; Hello World /&gt;
                        </p>
                        <p className='iam' data-aos="fade-up" data-aos-duration="500">
                            I am
                        </p>
                        <p className='name' data-aos="fade-up" data-aos-duration="600">
                            Mursal Furqan Kumbhar
                        </p>
                        <p className='location' data-aos="fade-up" data-aos-duration="600">
                            <i className="fas fa-map-marker-alt"></i> Rome, Italy
                        </p>
                        <div className='contact_reach'>
                            <>
                                {constants.social_media.map((link, index) => (
                                    <SocialMediaLink key={index} name={link.name} icon={link.icon} url={link.url} />
                                ))}
                                <a href={"https://twitter.com/mursalfurqan/"} target="_blank" rel="noopener noreferrer">
                                    <img src={Images.xLogo} alt="xLogoTwitter" className='xLogoTwitter' />
                                </a>
                            </>
                        </div>
                        <ModalButtons
                            togglePopup={togglePopup}
                            setIsAboutOpen={setIsAboutOpen}
                            setIsSkillsOpen={setIsSkillsOpen}
                            setIsExperienceOpen={setIsExperienceOpen}
                            setIsEducationOpen={setIsEducationOpen}
                            setIsProjectsOpen={setIsProjectsOpen}
                            setIsAchievementsOpen={setIsAchievementsOpen}
                            setIsWriteUpsOpen={setIsWriteUpsOpen}
                            setIsResearchOpen={setIsResearchOpen}
                            setIsResumeOpen={setIsResumeOpen}
                        />
                    </div>
                </div>
            </div>

            <div className='withLove'>
                <p className='loveInBox'>
                    🏗️ with 💖 by Mursal Furqan in ⚛
                </p>
                <div className='infoIcon' onMouseEnter={() => toggleCreditsVisibility("visible")} onMouseLeave={() => toggleCreditsVisibility("hidden")}>
                    <i className="fas fa-info-circle fa-2x"></i>
                </div>
            </div>

            {popupConfig.map((popup, index) => (
                <Popup key={index} show={popup.show} content={popup.content} handleClose={togglePopup(popup.setter)} />
            ))}

        </section>
    )
}