// Export Const Images
const Images = {
    self_png: require('./assets/Self.png'),
    MOSWord: require('./assets/work/07.png'),
    MOSPPT: require('./assets/work/06.png'),
    MOSExcel: require('./assets/work/05.png'),
    MOSWordExpert: require('./assets/work/04.png'),
    MIETrainer: require('./assets/work/03.png'),
    MTAPython: require('./assets/work/02.png'),
    IBMDS: require('./assets/work/09.png'),
    MSP: require('./assets/work/01.png'),
    MTANF: require('./assets/work/08.png'),
    DSDC: require('./assets/work/10.png'),
    MLSABeta: require('./assets/work/12-01.png'),
    MIEMT: require('./assets/work/13.png'),
    MIEExpert: require('./assets/work/14.png'),
    MCT: require('./assets/work/15.png'),
    CyberSecurity: require('./assets/work/16.png'),
    LogoWhite: require('./assets/work/logo_white.png'),
    RandomGradientGenerator: require('./assets/work/Random-Gradient-Generator.png'),
    GithubProjectsAPI: require('./assets/work/Github-Projects-API.png'),
    SndhTextBookLibrary: require("./assets/work/Sindh Textbook's Library.png"),
    MoreProjects: require('./assets/work/MoreProjects.png'),
    userProfiling: require('./assets/work/research/An Efficient Approach for User Profiling Through Social Media Analytics.png'),
    piaAnalysis: require('./assets/work/research/PIA Accidents Analysis Using Naïve Bayes Classifier.png'),
    heartDisease: require('./assets/work/research/Heart Disease Prediction using Machine Learning Algorithms.png'),
    imageDenoiser: require('./assets/work/research/Image Denoising Using Autoencoders.png'),
    anxietyDetection: require('./assets/work/research/Detecting and Relieving anxiety on-the-go using Machine Learning Techniques.png'),
    pythonLibrary: require('./assets/work/research/Python libraries for Data Science.png'),
    roboticClaw: require('./assets/work/research/Industrial Robotic Claw for Cottage Industries.png'),
    personal_Portfolio: require('./assets/work/Personal Portfolio.png'),
    anxo_relief_poster: require('./assets/work/anxo_relief_poster.png'),
    SmartYogaGym: require('./assets/work/SmartYogaGym.png'),
    eCommerce: require('./assets/work/eCommerce.png'),
    imgSlider: require('./assets/work/Random Gradient Generator.gif'),
    xLogo: require('./assets/work/twitter-x-logo-png-9.png'),
}

export default Images;