import React from 'react'
import Images from '../Images.js'

export default function AboutMe_Desktop() {
    return (
        <section className="about px-4 my-5" id="about_me">
            {/* <div className="marqueeText pl-0">
                <marquee>
                    About
                </marquee>
            </div> */}
            <div className="row m-0">

                <div className="about_me_icon pl-0">
                    <img
                        src={Images.self_png}
                        alt="Mursal Furqan"
                        className="img-fluid"
                    />
                </div>
                <div className="col-sm-12">
                    <h5 className="font-ram font-size-20 py-2 text-uppercase retro" data-aos="fade-up" data-aos-duration="1000">I'm Mursal Furqan</h5>
                    <p className="font-ram py-2 bio">
                        A CSE graduate with grave interest and
                        knowledge in programming and logic building. An
                        ML Engineer and DS with extensive hands-on experience
                        contributing to several open-source codes. A former
                        Microsoft Learn Student Ambassador, Amazon Web Services
                        Community Builder, Agora Student Ambassador, Hack Club
                        President, a web developer with a good grip on extensive
                        front-end development tools including React, Django,
                        HTML, CSS, and JavaScript along with Selenium for
                        Automation. Having a strong background in project
                        management and excellent communication skills.
                    </p>
                    <div className="d-flex flex-row flex-wrap justify-content-between py-1">
                        <div className="d-flex contact">
                            <p className="font-ram">
                                <i className="fas fa-phone"></i>
                            </p>
                            <p className="font-ram">
                                <i className="fas fa-envelope"></i>
                            </p>
                            <p className="font-ram">
                                <i className="devicon-github-plain"></i>
                                <span className=""> &nbsp; @mursalfk</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
