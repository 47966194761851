import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import constants from '../constants';

export default function Experience_Desktop() {
    return (
        <>
            {/* <div className="marqueeText pl-0">
                <marquee>
                    Experience
                </marquee>
            </div> */}
            <section className="reference py-5 px-4" id="experience">

                <div className="refer">
                    <h5 className="font-ram py-2 text-uppercase retro" data-aos="fade-up" data-aos-duration="1000">Experience</h5>
                </div>
                <div className="row mb-5">
                    {constants.experience.map((experience, index) => (
                        <div className="col-md-4 col-sm-6 mb-4 text-center experience_box" key={index}>
                            <div className="panel-body">
                                <span className='indexNumber'>
                                    {constants.experience.length - index}
                                </span>
                                <span className="icon text-secondary iconExperience">
                                    <i className={experience.className}></i>
                                </span>
                                <h3 className="font-ram">{experience.title}</h3>
                                <i>
                                    {experience.duration}
                                </i>
                                <p className="font-ram font-size-16">
                                    <br />
                                    <a href={experience.url}>
                                        {experience.location}
                                    </a>
                                    <br />
                                    <span dangerouslySetInnerHTML={{ __html: experience.details }} />
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </>
    )
}
