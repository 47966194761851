import React from "react";

const Popup = props => {
  const closePopup = e => {
    if (e.target.className === "popup-box") {
      props.handleClose();
    }
  };
  return (
    <div className="inPopUp">
      <div className={props.show ? "popup-box" : "popup-box hide"} onClick={closePopup}>
        <div className="box">
          <span className="close-icon" onClick={props.handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              // stroke-width="2"
              // stroke-linecap="round"
              // stroke-linejoin="round"
              className="feather feather-x popup-close-icon"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>

          </span>
          {props.content}
        </div>
      </div>
    </div>
  );
};

export default Popup;