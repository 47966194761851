import React from 'react'
import 'react-multi-carousel/lib/styles.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import Images from '../Images';
import { useEffect } from 'react';


export default function ProjectsCompiler(props) {
    useEffect(() => {
    }, [])
    return (
        <>
            {props.projects.map((project, index) => (
                <Card sx={{ width: 345 }} key={index} className="mx-2 my-2 projectCard">
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="140"
                            image={project.image}
                            alt="github"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                {project.title}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {project.description}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                    <div className='projectCardButtons'>
                        <CardActions>
                            {(project.link && project.link !== '') &&
                                <a href={project.link} target="_blank" rel="noreferrer">
                                    <Button size="small" color="primary">
                                        Code
                                    </Button>
                                </a>
                            }
                            {(project.demo && project.demo !== '') &&
                                <a href={project.demo} target="_blank" rel="noreferrer">
                                    <Button size="small" color="primary">
                                        View Demo
                                    </Button>
                                </a>
                            }
                        </CardActions>
                        <div className='tech_stack'>
                            {project.tech.map((tech_indi, index) => (
                                <div className='tech' key={index}>
                                    <i className={`${tech_indi} skill_indi`}></i>
                                </div>
                            ))}
                        </div>
                    </div>
                </Card>
            ))}
            <Card sx={{ width: 345 }} className="mx-2 my-2 projectCard">
                <CardActionArea>
                    <CardMedia
                        component="img"
                        height="140"
                        image={Images.MoreProjects}
                        alt="github"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            More Projects
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            See more projects on Github
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <div className='projectCardButtons'>
                    <CardActions>
                        <a href='https://github.com/mursalfk' target="_blank" rel="noreferrer">
                            <Button size="small" color="primary">
                                View on Github
                            </Button>
                        </a>
                    </CardActions>
                </div>
            </Card>
        </>
    )
}
