import Main from './Main/main.js'
import './style.css'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Blogs from './Sections/blogs.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import PageNotFound from './PageNotFound.js';

function App() {
  return (
    <Main />
  );
}

export default App;
