import React from 'react'
import 'react-multi-carousel/lib/styles.css';
import ProjectsCompiler from './ProjectsCompiler';
import constants from '../constants';

export default function Projects_Desktop() {

    return (
        <>
            {/* <div className="marqueeText pl-0">
                <marquee>
                    Projects
                </marquee>
            </div> */}

            <div className="refer py-3">
                <h5 className="font-ram text-uppercase retro personalProjectsH1" data-aos="fade-up" data-aos-duration="1000">Personal Projects</h5>
            </div>
            <div className='projectsSectionDiv'>
                <ProjectsCompiler projects={constants.projects} />
            </div>
        </>
    )
}
