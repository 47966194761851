import { useEffect, useState } from 'react';
import axios from 'axios';

export default function FetchCSVData(props) {
    const [csvData, setCsvData] = useState({});

    useEffect(() => {
        fetchCSVData(); // Fetch the CSV data when the component mounts
    }, []);

    const csvUrls = {
        'Research': 'https://docs.google.com/spreadsheets/d/e/2PACX-1vS84ogw-JSTTymbfc0YFlpa7acv1cZCZUf-Ya6qWRuvdoQQMpPQBl43war4ER3xDP-W8cn3w14TpMAI/pub?gid=0&single=true&output=csv',
        'Education': 'https://docs.google.com/spreadsheets/d/e/2PACX-1vS84ogw-JSTTymbfc0YFlpa7acv1cZCZUf-Ya6qWRuvdoQQMpPQBl43war4ER3xDP-W8cn3w14TpMAI/pub?gid=1739973332&single=true&output=csv',
        // Add more CSV URLs and their names here
    };

    const fetchCSVData = () => {
        const promises = Object.entries(csvUrls).map(([name, url]) => {
            return axios.get(url).then((response) => parseCSV(response.data)).then((data) => ({ [name]: data }));
        });

        Promise.all(promises)
            .then((results) => {
                const combinedCsvData = Object.assign({}, ...results); // Combine data from multiple CSVs
                setCsvData(combinedCsvData);
            })
            .catch((error) => {
                console.error('Error fetching CSV data:', error);
            });
    };

    function parseCSV(csvText) {
        const rows = csvText.split(/\r?\n/);        // Use a regular expression to split the CSV text into rows while handling '\r'
        const headers = rows[0].split(',');        // Extract headers (assumes the first row is the header row)
        const data = [];        // Initialize an array to store the parsed data
        for (let i = 1; i < rows.length; i++) {
            const rowData = rows[i].split(',');          // Use the regular expression to split the row while handling '\r'
            const rowObject = {};
            for (let j = 0; j < headers.length; j++) {
                rowObject[headers[j]] = rowData[j];
            }
            data.push(rowObject);
        }
        return data;
    }

    return csvData;
}
