import React from 'react';
import PopupButton from './PopupButton'; // Import a component for the individual buttons

function ModalButtons({
    togglePopup,
    setIsAboutOpen,
    setIsSkillsOpen,
    setIsExperienceOpen,
    setIsEducationOpen,
    setIsProjectsOpen,
    setIsAchievementsOpen,
    setIsWriteUpsOpen,
    setIsResearchOpen,
    setIsResumeOpen
}) {
    return (
        <div className='modalButtonDiv'>
            <PopupButton text="About Me" togglePopup={togglePopup(setIsAboutOpen)} />
            <PopupButton text="Skills" togglePopup={togglePopup(setIsSkillsOpen)} />
            <PopupButton text="Experience" togglePopup={togglePopup(setIsExperienceOpen)} />
            <PopupButton text="Education" togglePopup={togglePopup(setIsEducationOpen)} />
            <PopupButton text="Personal Projects" togglePopup={togglePopup(setIsProjectsOpen)} />
            <PopupButton text="Achievements" togglePopup={togglePopup(setIsAchievementsOpen)} />
            <PopupButton text="Articles" togglePopup={togglePopup(setIsWriteUpsOpen)} />
            <PopupButton text="Research" togglePopup={togglePopup(setIsResearchOpen)} />
            <PopupButton text="Resume" togglePopup={togglePopup(setIsResumeOpen)} />
            {/* <PopupButton text="Misc" togglePopup={togglePopup(setIsMiscOpen)} /> */}
            {/* <button onClick={() => navigate('blogs')} className="newBtn">Blogs</button> */}
        </div>
    );
}

export default ModalButtons;
