import React from 'react'
import 'react-multi-carousel/lib/styles.css';
import constants from '../constants';

export default function Achievements_Desktop() {
    return (
        <>
            {/* <div className="marqueeText pl-0">
                <marquee>
                    Achievements
                </marquee>
            </div> */}

            <section className="work certifications py-5 px-4" id="certifications">
                <div className="py-3">
                    <h5 className="font-ram font-size-20 py-2 text-uppercase retro" data-aos="fade-up" data-aos-duration="1000">Achievements</h5>
                </div>
                <div className="row text-center">
                    {constants.Qwiklabs && constants.Qwiklabs.map((qwiklab, index) => {
                        return (
                            <div className="col-sm-2 pb-1" key={index}>
                                <img src={qwiklab.image} height="100" alt={qwiklab.title}
                                    className="img-fluid tilt" />
                                {qwiklab.link ? (
                                    <>
                                        <h4 className="text-uppercase font-os font-size-16 text-muted achievement-text">
                                            <a href={qwiklab.link} target="_blank" rel="noopener noreferrer">
                                                {qwiklab.title} &nbsp;
                                                <span className='check_achievements'>
                                                    <i className="blueCheck fas fa-check-circle text-success"></i>
                                                </span>
                                            </a>
                                            <i><h6 className="text-uppercase font-os font-size-16 text-muted">By {qwiklab.issuer ?? qwiklab.issuer}</h6></i>
                                        </h4>
                                    </>
                                ) : (
                                    <>
                                        <h4 className="text-uppercase font-os font-size-16 text-muted py-2 achievement-text">
                                            {qwiklab.title}
                                            <i>
                                                <h6 className="text-uppercase font-os font-size-16 text-muted">
                                                    By {qwiklab.issuer ?? qwiklab.issuer}
                                                </h6>
                                            </i>
                                        </h4>
                                    </>
                                )}
                            </div>
                        )
                    })}

                    {constants.Achievements && constants.Achievements.map((Achievements, index) => {
                        return (
                            <React.Fragment key={index}>
                                <div className="col-sm-2 pb-1">
                                    <img src={Achievements.image} height="100" alt={Achievements.title}
                                        className="img-fluid tilt" />
                                    {Achievements.link ? (
                                        <>
                                            <h4 className="text-uppercase font-os font-size-16 text-muted achievement-text">
                                                <a href={Achievements.link} target="_blank" rel="noopener noreferrer">
                                                    {Achievements.title} &nbsp;
                                                    <span className='check_achievements'>
                                                        <i className="blueCheck fas fa-check-circle text-success"></i>
                                                    </span>
                                                </a>
                                                <i><h6 className="text-uppercase font-os font-size-16 text-muted">By {Achievements.issuer ?? Achievements.issuer}</h6></i>
                                            </h4>
                                        </>
                                    ) : (
                                        <>
                                            <h4 className="text-uppercase font-os font-size-16 text-muted py-2 achievement-text">
                                                {Achievements.title}
                                                <i>
                                                    <h6 className="text-uppercase font-os font-size-16 text-muted">
                                                        By {Achievements.issuer ?? Achievements.issuer}
                                                    </h6>
                                                </i>
                                            </h4>
                                        </>
                                    )}
                                </div>
                            </React.Fragment>
                        )
                    })
                    }
                </div>
            </section>
        </>
    )
}
