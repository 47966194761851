import React from 'react';

function PopupButton({ text, togglePopup }) {
  return (
    <button onClick={togglePopup} className="newBtn">
      {text}
    </button>
  );
}

export default PopupButton;
