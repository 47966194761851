import React from 'react';
import MobileBanner from '../Sections/Mobile_banner.js';
import DesktopBanner from '../Sections/Desktop_Banner.js';

export default function Main() {
  const isDesktop = window.innerWidth >= 768;

  const BannerComponent = isDesktop ? DesktopBanner : MobileBanner;

  return (
    <main id="site-main">
      <div className="row m-0 bannerRow">
        <div className="mainBannerClass px-0">
          <section className="site-banner" id="home">
            <div className={`banner-area ${isDesktop ? '' : 'mobile-banner'} text-center`}>
              <BannerComponent />
            </div>
          </section>
        </div>
      </div>
    </main>
  );
}
