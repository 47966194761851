import React from 'react'

export default function WriteUps_Desktop(props) {
    const writeUps = props.writeUps;

    return (
        <>
            {/* <div className="marqueeText pl-0">
                <marquee>
                    Writeups
                </marquee>
            </div> */}

            <section className="news writeups px-4" id="publications">
                <div className="">
                    <h5 className="font-ram text-uppercase retro" data-aos="fade-up" data-aos-duration="1000">Articles</h5>
                </div>
                <div className="row">
                    {/* Show only first 20 results */}
                    {writeUps.slice(0, 19).map((writeUp, index) => (
                        <React.Fragment key={index}>
                            <span href={writeUp.url} target="_blank" rel="noreferrer" className="cardArticle">
                                <div className="card mx-2 my-2 writeUpCard" key={index}>
                                    <div className='imgContainer'>
                                        <img src={writeUp.image} className="card-img-top" alt="..." />
                                    </div>
                                    <div className="card-body">
                                        <h5 className="card-title">{writeUp.title}</h5>
                                        <p className="card-text">{writeUp.description}</p>
                                        <a href={writeUp.url} target="_blank" rel="noreferrer" className="readMoreSpan btn btn-primary">Read More</a>
                                    </div>
                                </div>
                            </span>
                        </React.Fragment >
                    ))}
                    <span href='https://dev.to/mursalfk' target="_blank" rel="noreferrer" className="cardArticle">
                        <div className="card mx-2 my-2 writeUpCard">
                            <div className='imgContainer mobImg'>
                                <img src='https://iconape.com/wp-content/png_logo_vector/cib-dev-to.png' className="card-img-top" alt="..." />
                            </div>
                            <div className="card-body">
                                <h5 className="card-title">Mursal Furqan</h5>
                                <p className="card-text">Checkout more articles on Dev.to/mursalfk</p>
                                <a href='https://dev.to/mursalfk' target="_blank" rel="noreferrer" className="readMoreSpan btn btn-primary">View More</a>
                            </div>
                        </div>
                    </span>
                </div>
            </section>
        </>
    )
}
