import React from 'react'
import 'react-circular-progressbar/dist/styles.css';
import SkillProgress from './SkillProgress';
import constants from '../constants';

export default function Skills_Desktop() {
    return (
        <>
            {/* <div className="marqueeText pl-0">
                <marquee>
                    Skills
                </marquee>
            </div> */}
            <section className="skill px-4 py-10" id="skills">

                <div className="ability py-3">
                    <h5 className="font-ram font-size-20 py-2 text-uppercase retro" data-aos="fade-up" data-aos-duration="1000">Skills</h5>
                </div>
                <div className="row padding_bottom_skillset skills_row">
                    {constants.SkillSetValue.map((skill, index) => {
                        return (
                            <SkillProgress key={index} value={skill.value} className={skill.className} />
                        )
                    })}
                </div>
            </section>
        </>
    )
}
